import request from '@/utils/request'


// 查询音频业务列表
export function listAudio(query) {
  return request({
    url: '/platform/audio/list',
    method: 'get',
    params: query
  })
}

// 查询音频业务分页
export function pageAudio(query) {
  return request({
    url: '/platform/audio/page',
    method: 'get',
    params: query
  })
}

// 查询音频业务详细
export function getAudio(data) {
  return request({
    url: '/platform/audio/detail',
    method: 'get',
    params: data
  })
}

// 新增音频业务
export function addAudio(data) {
  return request({
    url: '/platform/audio/add',
    method: 'post',
    data: data
  })
}

// 修改音频业务
export function updateAudio(data) {
  return request({
    url: '/platform/audio/edit',
    method: 'post',
    data: data
  })
}

// 删除音频业务
export function delAudio(data) {
  return request({
    url: '/platform/audio/delete',
    method: 'post',
    data: data
  })
}
