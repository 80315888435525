<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="音频名稱" prop="audioName" >
        <a-input v-model="form.audioName" placeholder="请输入音频名稱" />
      </a-form-model-item>
<!--      <a-form-model-item label="音频图片" prop="audioPic" >
        <a-input v-model="form.audioPic" placeholder="请输入音频图片" />
      </a-form-model-item>-->
      <a-form-model-item label="音频封面" prop="audioPic" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.audioPic" :src="form.audioPic" alt="封面" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M </span>
      </a-form-model-item>

<!--      <a-form-model-item label="音频格式" prop="audioFormat" >
        <a-input v-model="form.audioFormat" placeholder="请输入音频格式" />
      </a-form-model-item>-->
      <a-form-model-item label="音频" prop="audioUrl" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest2'
          :beforeUpload="beforeUpload2"
          @change="handleChange2"
          accept=".mp3"
        >
          <audio v-if="form.audioUrl" :src="form.audioUrl" alt="音频" controls="controls"></audio>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">音频限制小于5M </span>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAudio, addAudio, updateAudio } from '@/api/platform/audio'
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        type: null,

        audioName: null,

        audioFormat: null,

        audioUrl: null,

        audioDuration: null,

        audioPic: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用戶id(关联用戶表主键)不能为空', trigger: 'blur' }
        ],
        audioName: [
          { required: true, message: '音频名稱不能为空', trigger: 'blur' }
        ],
        audioUrl: [
          { required: true, message: '音频url不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          console.log("res========>"+JSON.stringify(res))
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.audioPic = info.file.originFileObj.ossUrl;
      }
    },

    fileUploadRequest2(fileInfo) {
      this.uploadLoading = true
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          console.log("res========>"+JSON.stringify(res))
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload2: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("音频大小不能超过5M----")
          return reject(false)
        }
        this.getTimes(file);
        resolve(true)
      });
    },
    getTimes(file) {  //获取时长
      var content = file;
      let audio = new Audio()
      audio.src = URL.createObjectURL(content);
      audio.addEventListener("loadedmetadata", (_event) => {
        var duration = audio.duration;
        var audioDuration = parseInt(duration / 60.0)*60+parseInt((parseFloat(duration/60.0)-parseInt(duration / 60.0))*60)
        // var audioDuration = parseInt(duration / 60.0) <= 0 ?
        //   parseInt((parseFloat(duration/60.0)-parseInt(duration / 60.0))*60)+'秒' :
        //   parseInt(duration / 60.0)+'分钟'+parseInt((parseFloat(duration/60.0)-parseInt(duration / 60.0))*60)+'秒';
        this.form.audioDuration = audioDuration;
      });
    },
    handleChange2(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.audioUrl = info.file.originFileObj.ossUrl;
      }
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        audioName: null,
        audioUrl: null,
        audioPic: null,

      }
     /* this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAudio({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAudio(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAudio(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
